<script setup lang="ts">
import { ParagraphSingleTeaser, MediaImageFragment } from '~/api/graphql/generated'

const props = defineProps<{
  entity: ParagraphSingleTeaser
}>()

const data = computed(() => ({
  title: props.entity.fieldSingleTeaser?.entity?.title ?? '',
  description: props.entity?.fieldSingleTeaser?.entity?.fieldDescription?.processed ?? '',
  image: props.entity.fieldSingleTeaser?.entity?.fieldImage?.entity as MediaImageFragment,
  link: {
    url: props.entity.fieldSingleTeaser?.entity?.fieldCta?.url?.path,
    text: props.entity.fieldSingleTeaser?.entity?.fieldCta?.title,
    target: props.entity.fieldSingleTeaser?.entity?.fieldCta?.options?.attributes?.target || '_self',
  },
}))

const large = computed(() => props.entity?.fieldSize === 'big')
</script>

<template>
  <Section v-animate="{ cluster: true }" v-bind="{ large }">
    <TeaserSingle :data="data" v-bind="{ large }">
      <Button
        v-if="data.link.url"
        v-animate="{ animation: 'vertical-reveal' }"
        secondary
        :to="data.link.url"
        :title="data.link.text"
        :target="data.link.target"
      >
        {{ data.link.text }}
      </Button>
    </TeaserSingle>
  </Section>
</template>
